import {
  Accordion,
  AccordionSummary,
  Alert,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack
} from '@mui/material';
import { TenantPage } from 'components/common';
import SectionTitle from 'components/common/SectionTitle';
import { ITransportDocument, ILabelsDetails, ITransportDocumentLine } from 'interfaces';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  transportDocumentLineService,
  transportDocumentService,
  unapprovedMovementService
} from 'services/api';
import { printLabel } from 'services/zebra';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { currencyFormatter } from 'helpers/currency-formatter';
import FileUploader from 'components/common/file-uploader';
import dayjs from 'dayjs';
import toast from 'features/toast';
import { TransportDocumentLinesColumns } from 'components/common/enhanced/common-headers/transport-document-lines';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TransportDocumentForm from '../TransportDocumentForm';
import { GridSelectionModel } from '@mui/x-data-grid-premium';
import { UnapprovedOrderlessMovementColumns } from 'components/common/enhanced/common-headers/unapproved-movements-orderless';
import { forEach } from 'lodash';
import { CircularProgressWithLabel } from './CircularProgressWithLabel';
import { DocumentTableCardComponent } from 'components/common/documentLM/DocumentTableCardComponent';

export function TransportDocumentDetail() {
  const { t } = useTranslation();
  const prefix = 'pages.ddt.detail';
  const { id } = useParams();

  const datagridRefresh = useRef(null);
  const rowsRef = useRef<ITransportDocumentLine[]>([]);

  const [ddt, setDdt] = useState<ITransportDocument>();
  const [movements, setMovements] = useState<GridSelectionModel>([]);
  const [openDialog, setOpenDialog] = useState(false);

  const [openPrintLabelDialog, setOpenPrintLabelDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [printBarcodes, setPrintBarcodes] = useState<number[]>([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    transportDocumentService.get(+id).then(setDdt);
  }, []);

  const downloadableFields = [
    'invoice_file',
    'ddt_file',
    'invoice_revision_file',
    'extra_1_file',
    'extra_2_file',
    'extra_3_file'
  ];

  const updateDDT = (values) => {
    delete values['credit_memo_amount'];
    transportDocumentService.update(ddt.id, values).then((res) => {
      setDdt(res);
      toast.success('DDT aggiornato con successo');
    });
  };

  const attachLines = () => {
    transportDocumentService.attachLines(ddt.id).then(() => {
      datagridRefresh.current();
    });
  };

  const unapprovedMovementColumns = UnapprovedOrderlessMovementColumns();

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setMovements([]);
    setOpenDialog(false);
  };

  const handleAttachLines = () => {
    transportDocumentService.attachUnapprovedLines(ddt.id, movements);
    handleCloseDialog();
  };

  const handleOpenPrintLabelDialog = () => {
    setOpenPrintLabelDialog(true);
    setProgress(0);
  };

  const handleClosePrintLabelDialog = () => {
    setOpenPrintLabelDialog(false);
    setProgress(0);
  };

  const handleRetreiveDetailsToPrint = () => {
    // ok iniziamno stampando la lista di movimenti
    // transportDocumentLineService.getBulkPrintLables(ddt.id).then((res) => {
    //   if (!res) toast.error(t(prefix + '.error-print'));
    //   else {
    //     setLabelsToPrint(res);
    //   }
    // });

    handleOpenPrintLabelDialog();
    setProgress(totalQuantity);
  };

  const handlePrintAllMovements = (printAll = false) => {
    // conferma per stampare tutti i movimenti
    setIsLoading(true);
    forEach(rowsRef.current, (ddtLine) => {
      for (let i = 0; i < (printAll ? ddtLine.quantity : 1); i++) {
        printLabel(
          ddtLine.product,
          ddtLine.product_batch,
          ddtLine.expiration_date,
          ddtLine.description,
          printBarcodes.includes(ddtLine.id)
        );
      }
      setProgress(progress + ddtLine.quantity);
    });
    setIsLoading(false);
    handleClosePrintLabelDialog();
  };

  const totalQuantity = rowsRef.current.reduce((sum, labelDetail) => {
    return sum + labelDetail.quantity;
  }, 0);

  const handleCheckBarcode = (id: number) => {
    setPrintBarcodes([...printBarcodes, id]);
  };

  const handleUncheckBarcode = (id: number) => {
    setPrintBarcodes(printBarcodes.filter((b) => b !== id));
  };

  const handleOnCloseUpdateDDT = () => {
    if (datagridRefresh.current) datagridRefresh.current();
  };

  const columns = TransportDocumentLinesColumns(
    false,
    true,
    handleOnCloseUpdateDDT,
    handleCheckBarcode,
    handleUncheckBarcode
  );

  return (
    <TenantPage
      title={t(`${prefix}.title`) + ' ' + ddt?.ref}
      subtitle={t(`${prefix}.subtitle`)}
      entityCode={ddt?.ref}>
      <div>
        <div className="w-full">
          {ddt && ddt.amount != null && +ddt.amount !== +ddt.expected_amount && (
            <Alert severity="warning" className="w-full">
              {t(`${prefix}.incoerent-amounts`)}
            </Alert>
          )}
        </div>
        <div className="mb-4">
          <SectionTitle>{t(`${prefix}.header`)}</SectionTitle>
          <div className="flex flex-wrap my-4">
            <div className="w-3/4 pr-8">
              {ddt && <TransportDocumentForm onSubmit={updateDDT} transport_document={ddt} />}
            </div>
            <div className="w-1/4">
              {ddt && (
                <div>
                  <b>{t(`entities.ddt.amount`)}</b>
                  <div className="flex flex-wrap">{currencyFormatter(ddt.amount)}</div>
                  <b>{t(`${prefix}.invoice-expected`)}</b>
                  <div className="flex flex-wrap">{currencyFormatter(ddt.expected_amount)}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mb-4">
          <SectionTitle>{t(`${prefix}.files`)}</SectionTitle>
          {ddt && (
            <div className="flex flex-wrap">
              {downloadableFields.map((field, i) => (
                <div key={i} className={`w-1/${downloadableFields.length}`}>
                  <span>{t(`entities.ddt.${field}`)}:</span>
                  <FileUploader
                    service={transportDocumentService}
                    entityId={ddt.id}
                    attachmentName={field}
                    filename={ddt[field]}
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        {ddt && (
          <div className="w-full p-4">
            <Card variant="outlined">
              <DocumentTableCardComponent
                originalService={'ddt'}
                objectId={ddt.id}
                dictParams={{ ddt: id }}
                folder={'menu.ddt/' + ddt.ref}
                folder_description={`DDT: ${ddt.ref}; Data: ${ddt.date}; Ordine: ${ddt.order?.ref}; Fattura: ${ddt.invoice_ref}; Magazzino: ${ddt.warehouse}`}
              />
            </Card>
          </div>
        )}

        <div className="mb-4">
          <SectionTitle>{t(`${prefix}.order-list`)}</SectionTitle>
          {ddt && ddt?.order && (
            <Accordion key={ddt.order.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography>
                  <b>{ddt.order.ref}</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="flex flex-wrap">
                    <div className="mr-4">
                      {t('global.status')}: <b>{ddt.order.status}</b>
                    </div>
                    <div className="mr-4">
                      {t('global.supplier')}: <b>{ddt.order.supplier}</b>
                    </div>
                    <div className="mr-4">
                      {t('global.warehouse')}: <b>{ddt.order.warehouse}</b>
                    </div>
                    <div className="mr-4">
                      {t('entities.order.from_request')}: <b>{ddt.order.from_request}</b>
                    </div>
                    <div className="mr-4">
                      {t('entities.order.created_at')}:{' '}
                      <b>{dayjs(ddt.order.created_at).format('YYYY-MM-DD HH:mm')}</b>
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
        <br />
        {ddt && (
          <>
            <SectionTitle>
              <div className="p-2">
                <div className="flex justify-start">{t(`${prefix}.movement-list`)}</div>
                <div className="flex justify-end">
                  <Button
                    onClick={() => handleRetreiveDetailsToPrint()}
                    variant="outlined"
                    color="primary">
                    {t(`${prefix}.print-all`)}
                  </Button>
                </div>
              </div>
            </SectionTitle>
            {id && (
              <EnhancedDataGrid
                columns={columns}
                service={transportDocumentLineService}
                outerParams={{ document_id: id }}
                rows={rowsRef}
                refresh={datagridRefresh}
              />
            )}
          </>
        )}
      </div>
      <Dialog fullWidth maxWidth="lg" open={openDialog} onClose={() => handleCloseDialog()}>
        <DialogTitle>Aggiungi fonte di costo</DialogTitle>
        <DialogContent>
          <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column">
            <Paper sx={{ flex: 1, mx: 'auto', width: '95%', p: 1 }}>
              <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                <EnhancedDataGrid
                  columns={unapprovedMovementColumns}
                  service={unapprovedMovementService}
                  outerParams={{ noOrderCode: 'true' }}
                  checkboxSelection={true}
                  selectionModelChange={(newRowSelectionModel) => {
                    setMovements(newRowSelectionModel);
                  }}
                />
              </Stack>
            </Paper>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog()}>{t('actions.cancel')}</Button>
          <Button onClick={() => handleAttachLines()}>{t('global.add')}</Button>
        </DialogActions>
      </Dialog>
      {/* Print Label */}
      <Dialog fullWidth open={openPrintLabelDialog} onClose={() => handleClosePrintLabelDialog()}>
        <DialogTitle sx={{ fontSize: '28px' }}>{t(prefix + '.confirmation-print')}</DialogTitle>
        <DialogContent sx={{ fontSize: '22px' }}>
          {!isLoading ? (
            <div> {t(prefix + '.confirmation-description-text', { quantity: totalQuantity })}</div>
          ) : (
            <div>
              {t(prefix + '.print-in-progress')}
              <br />
              <CircularProgressWithLabel value={progress} />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => handleClosePrintLabelDialog()}>
            {t('actions.cancel')}
          </Button>
          <Button onClick={() => handlePrintAllMovements()}>{t(prefix + '.single-print')}</Button>
          <Button variant="contained" onClick={() => handlePrintAllMovements(true)}>
            {t(prefix + '.all-print', { quantity: totalQuantity })}
          </Button>
        </DialogActions>
      </Dialog>
    </TenantPage>
  );
}

export default TransportDocumentDetail;
